export default [
    {
      path: "/marketplace",
      name: "marketplace",
      component: () => import("@/views/marketplace/listmarketplace.vue"),
      meta: {
        resource: "ACL",
        action: "all",
      },
    },
    {
      path: "/marketplace/show/:id",
      name: "marketplaceDetail",
      component: () => import("@/views/marketplace/marketplaceDetail.vue"),
      meta: {
        resource: "ACL",
        action: "all",
      },
    },
    {
      path: "/marketplace/tags/:tag",
      name: "ScriptsByTag",
      component: () => import("@/views/marketplace/marketplaceTags.vue"),
      meta: {
        resource: "ACL",
        action: "all",
      },
    },
    {
      path: "/marketplace/manage",
      name: "adminMarketplace",
      component: () => import("@/views/marketplace/marketplaceAdmin.vue"),
      meta: {
        resource: "ACL",
        action: "all",
      },
    },
    {
      path: "/marketplace/detail/:id",
      name: "adminMarketplaceDetail",
      component: () => import("@/views/marketplace/marketplaceAdminDetail.vue"),
      meta: {
        resource: "ACL",
        action: "all",
      },
    },
    {
      path: "/marketplace/update/:id",
      name: "adminMarketplaceUpdate",
      component: () => import("@/views/marketplace/marketplaceUpdate.vue"),
      meta: {
        resource: "ACL",
        action: "all",
      },
    },
    {
      path: "/marketplace/create",
      name: "adminMarketplaceCreate",
      component: () => import("@/views/marketplace/marketplaceCreate.vue"),
      meta: {
        resource: "ACL",
        action: "all",
      },
    },

    
  ]