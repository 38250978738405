export default [

  {
    path: "/market-manager/applications-search",
    name: "market-manager-market-search",
    component: () =>
      import("@/views/market-manager/ApplicationsSearch.vue"),
    meta: {
      resource: "ACL",
      action: "all",
    },
  },
  {
    path: "/market-manager/accounts-search",
    name: "market-manager-accounts-search",
    component: () =>
      import("@/views/market-manager/AccountsSearch.vue"),
    meta: {
      resource: "ACL",
      action: "all",
    },
  },
  {
    path: "/market-manager/favorite-accounts",
    name: "favorite-accounts",
    component: () =>
      import("@/views/market-manager/FavoriteAccounts.vue"),
    meta: {
      resource: "ACL",
      action: "all",
    },
  },
  {
    path: "/market-manager/favorites-apps",
    name: "favorites-apps",
    component: () =>
      import("@/views/market-manager/FavoriteApps.vue"),
    meta: {
      resource: "ACL",
      action: "all",
    },
  },
];
