export default [
  {
    path: "/pages/payment-method",
    name: "payment-method",
    component: () => import("@/views/subscription/PayementMethod.vue"),
    meta: {
      resource: "ACL",
      action: "all",
    },
  },
  {
    path: "/pages/plan-selection",
    name: "plan-selection",
    component: () => import("@/views/subscription/PlanSelection.vue"),
    meta: {
      resource: "ACL",
      action: "all",
    },
  },
];
