export default [
  {
    path: "/pages/coupons",
    name: "coupons",
    component: () =>
      import("@/views/admin/coupon/ListCoupon.vue"),
  },
  {
    path: "/pages/add-coupon",
    name: "add-coupons",
    component: () =>
      import("@/views/admin/coupon/AddCoupon.vue"),
  },
];
