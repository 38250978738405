export default [
    {
      path: "/discord/join",
      name: "discord-group",
      component: () => import("@/views/discord/discordVue.vue"),
      meta: {
        resource: "ACL",
        action: "all",
      },
    },
  ]