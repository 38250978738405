export default [
  {
    path: "/pages/:id/user-profile",
    name: "user-profil",
    component: () => import("@/views/register/Profile.vue"),
    meta: {
      resource: "ACL",
      action: "all",
    },
  },
];
