export default [
  {
    path: "/niche-manager",
    name: "niche-manager",
    component: () => import("@/views/top-collections/NicheManger.vue"),
    meta: {
      resource: "ACL",
      action: "all",
    },
  },
  {
    path: "/niche-manager/removed-apps",
    name: "niche-manager-removed-apps",
    component: () => import("@/views/top-collections/topCollsRemoved.vue"),
    meta: {
      resource: "ACL",
      action: "all",
    },
  },
  {
    path: "/niche-manager/top-niches-guide",
    name: "niche-manager-guides",
    component: () => import("@/views/top-collections/topCollsTopNichesGuide.vue"),
    meta: {
      resource: "ACL",
      action: "all",
    },
  },
  {
    path: "/niche-manager/top-niches-coloring",
    name: "niche-manager-coloring",
    component: () => import("@/views/top-collections/topCollsTopNichesColoring.vue"),
    meta: {
      resource: "ACL",
      action: "all",
    },
  },
  {
    path: "/niche-manager/top-niches-wallpaper",
    name: "niche-manager-wallpaper",
    component: () => import("@/views/top-collections/topCollsTopNichesWallpaper.vue"),
    meta: {
      resource: "ACL",
      action: "all",
    },
  },
  {
    path: "/niche-manager/top-niches-fakecalls",
    name: "niche-manager-fakecalls",
    component: () => import("@/views/top-collections/topCollsTopNichesFakecalls.vue"),
    meta: {
      resource: "ACL",
      action: "all",
    },
  },
  {
    path: "/niche-manager/top-niches-vpn",
    name: "niche-manager-vpn",
    component: () => import("@/views/top-collections/topCollsTopNichesVpn.vue"),
    meta: {
      resource: "ACL",
      action: "all",
    },
  },
  {
    path: "/niche-manager/top-niches-10-k",
    name: "niche-manager-10-k",
    component: () => import("@/views/top-collections/topCollsTop10K.vue"),
    meta: {
      resource: "ACL",
      action: "all",
    },
  },
];
