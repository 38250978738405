export default [
  {
    path: "/top-charts/top",
    name: "top-apps-charts",
    component: () => import("@/views/top-charts/topCharts.vue"),
    meta: {
      resource: "ACL",
      action: "all",
    },
  },
];
