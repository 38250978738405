export default [
  {
    path: "/email-invite",
    name: "auth-invite",
    component: () =>
      import("@/views/register/InviteEmail.vue"),
    meta: {
      layout: "full",
      resource: "Auth",
      redirectIfLoggedIn: true,
    },
  },
  {
    path: "/success-sending",
    name: "message-invite",
    component: () =>
      import("@/views/register/MessageInviteEmail.vue"),
      meta: {
        layout: "full",
        resource: "Auth",
        redirectIfLoggedIn: false,
      },
  },
  {
    path: "/success-register",
    name: "message-welcome",
    component: () =>
      import("@/views/register/WelcomeRegisterMessage.vue"),
      meta: {
        layout: "full",
        resource: "Auth",
        redirectIfLoggedIn: false,
      },
  },
];
