export default [
    {
      path: "/myapps",
      name: "user-apps-by-account",
      component: () => import("@/views/myApps/myAppsAcounts.vue"),
      meta: {
        resource: "ACL",
        action: "all",
      },
    },

  
  
    
  ]