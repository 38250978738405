export default  {
  state: {
    planCheck: null,
  },
  mutations: {
    // Define mutations to change the state
    setplanCheck(state, newValue) {
      state.planCheck = newValue;
    },
  },
  actions: {
    // Define actions to commit mutations
    updateplanCheck({ commit }, newValue) {
      commit('setplanCheck', newValue);
    },
  },
  getters: {
    // Define getters to read the state
    getplanCheck: state => state.planCheck,
  },
};

