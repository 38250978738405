export default [
  {
    path: "/support",
    name: "support",
    component: () => import("@/views/pages/support/Support.vue"),
    meta: {
      resource: "ACL",
      action: "all",
    },
  },
];
