import { Ability } from '@casl/ability'
import { initialAbility } from './config'
import { getAccessTokenFromCookie } from '@/auth/utils'

//  Read ability from localStorage
// * Handles auto fetching previous abilities if already logged in user
// ? You can update this if you store user abilities to more secure place
// ! Anyone can update localStorage so be careful and please update this
// TODO: 
const data = getAccessTokenFromCookie();
var  existingAbility ;
if(data){
  const userData = data[2]
  existingAbility =  userData.ability
}else {
  existingAbility = null;
}


export default new Ability(existingAbility || initialAbility)