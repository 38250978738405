export default [
  {
    path: "/myprofile/apps",
    name: "apps",
    component: () => import("@/views/settings/appsManagement.vue"),
    meta: {
      resource: "ACL",
      action: "all",
    },
  },
  {
    path: "/myprofile/apps/:id,:country,:lang,:from,:route/info",
    name: "apps-info",

    component: () => import("@/views/settings/appsInfo.vue"),
    meta: {
      resource: "ACL",
      action: "all",
    },
  },
  {
    path: "/myprofile/apps/creation",
    name: "apps-creation",
    component: () => import("@/views/settings/appsGenerator.vue"),
    meta: {
      resource: "ACL",
      action: "all",
    },
  },
  {
    path: "/myprofile/apps/edit",
    name: "apps-edit",
    component: () => import("@/views/settings/editApps.vue"),
    meta: {
      resource: "ACL",
      action: "all",
    },
  },
]