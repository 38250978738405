import axios from "@axios";
export default {
  namespaced: true,
  state: {
    //?---------------------------- Settings state ----------------------------?//
    accounts: [],
    accountID: null,
    apps: [],
    appID: null,
  },
  getters: {},
  mutations: {
    //?---------------------------- Settings Mutations ----------------------------?//
    SETACCOUNTID(state, id) {
      state.accountID = id;
    },
    SETAPPID(state, id) {
      state.appID = id;
    },
    UPDATE_APPS(state, val) {
      state.apps = val;
    },
    UPDATE_ACCOUNTS(state, val) {
      state.accounts = val;
    },
  },
  actions: {
    //?---------------------------- Settings Actions ----------------------------?//
    getAllAccounts({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .post("/get-accounts")
          .then((response) => {
            if (response.status == 200) {
              commit("UPDATE_ACCOUNTS", response.data[0]);
              resolve(response);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    getAllApps({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .post("/get-apps")
          .then((response) => {
            if (response.status == 200) {
              // commit('UPDATE_DOMAINS', response.data[0])
              commit("UPDATE_APPS", response.data[0]);
              resolve(response);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    getAppsByAccount({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .get("/getAppsByAccount", {
            account_id: this.accSelected,
          })
          .then((response) => {
            if (response.status == 200) {
              commit("UPDATE_DOMAINS", response.data[0]);
              resolve(response);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    setAccountId({ commit }, id) {
      return new Promise((resolve, reject) => {
        commit("SETACCOUNTID", id);
        resolve();
      });
    },
    setAppId({ commit }, id) {
      return new Promise((resolve, reject) => {
        commit("SETAPPID", id);
        resolve();
      });
    },
  },
};
