import Vue from "vue";
import { getAccessTokenFromCookie } from "@/auth/utils";
// axios
import axios from "axios";
import router from "@/router";
import { deleteAccessTokenCookie } from "@/helper/helpers";
// Global variables
let theBaseURL = "https://app.applead.io/backend/public/api/";
// let theBaseURL = "https://dev.appsmanager.co/backend/public/api/";
// let theBaseURL = "http://127.0.0.1:8000/api/";
const axiosIns = axios.create({
  baseURL: theBaseURL,
});
// Request interceptor
axiosIns.interceptors.request.use(
  (config) => {
    const data = getAccessTokenFromCookie();
    if (data !== null && data !== undefined) {
      const authToken = data[0];
      if (authToken) {
        config.headers.Authorization = `Bearer ${authToken}`;
      }
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Response interceptor
axiosIns.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.statusText === "Unauthorized") {
      deleteAccessTokenCookie();
      router.push({ name: "auth-login" });
    }else{
      return Promise.reject(error.response);
    }
  }
);

Vue.prototype.$http = axiosIns;
export default axiosIns;
