const axios = require("@axios");
module.exports = {
  deleteAccessTokenCookie: () => {
    document.cookie =
      "auth_data=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; Secure; SameSite=Strict;";
  },
  checkUserPlan: async () => {
    try {
      const response = await axios.default.post("/checkUserSubscription");
      const { data } = response;
      const { message } = data;
      if (message === "subscribed") {
        return true;
      }
    } catch (err) {
      return false;
    }
  },
};
