import invitation from "./invitation";
import register from "./register";
import profile from "./profile";

const data = [
  ...invitation,
  ...profile,
  ...register,
];

export default data;
