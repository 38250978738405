export default [
  {
    path: "/pages/products",
    name: "paypal-products",
    component: () => import("@/views/admin/subscriptions/ProductList.vue"),
    meta: {
      resource: "ACL",
      action: "all",
    },
  },
  {
    path: "/pages/plans",
    name: "paypal-plans",
    component: () => import("@/views/admin/subscriptions/PlanList.vue"),
    meta: {
      resource: "ACL",
      action: "all",
    },
  },
  {
    path: "/pages/create-product",
    name: "paypal-create-product",
    component: () => import("@/views/admin/subscriptions/AddProduct.vue"),
    meta: {
      resource: "ACL",
      action: "all",
    },
  },
  {
    path: "/pages/create-plan",
    name: "paypal-create-plan",
    component: () => import("@/views/admin/subscriptions/AddPlan.vue"),
    meta: {
      resource: "ACL",
      action: "all",
    },
  },
];
