export default [
  {
    path: "/admin/news",
    name: "adminNews",
    component: () => import("@/views/news/news.vue"),
    meta: {
      resource: "ACL",
      action: "all",
    },
  },
  {
    path: "/news/create",
    name: "adminNewsCreate",
    component: () => import("@/views/news/createNew.vue"),
    meta: {
      resource: "ACL",
      action: "all",
    },
  },
  {
    path: "/news/update/:id",
    name: "adminNewsUpdate",
    component: () => import("@/views/news/updateNew.vue"),
    meta: {
      resource: "ACL",
      action: "all",
    },
  },


  
]