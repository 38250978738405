
/**
 * Return if user is logged in
 * This is completely up to you and how you want to store the token in your frontend application
 * e.g. If you are using cookies to store the application please update this function
 */
// eslint-disable-next-line arrow-body-style

export const getAccessTokenFromCookie = () => {
  const cookies = document.cookie.split(";");
  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i].trim();
    if (cookie.startsWith("auth_data=")) {
      const cookieValue = cookie.substring("auth_data=".length, cookie.length);
      const cookieData = JSON.parse(cookieValue);
      const accessToken = cookieData.accessToken;
      const userAccess = cookieData.userAccess;
      const userData = cookieData.userData;
      return [accessToken, userAccess, userData];
    }
  }
  return null;
};

export const isUserLoggedIn = () => {
  const access = getAccessTokenFromCookie();
  if (access !== null) {
    const accessToken = access[0]
    const userData = access[2]
    if ((userData && accessToken) != null) return true;
    return false;
  }
};

export const getUserData = () => userData;

/**
 * This function is used for demo purpose route navigation
 * In real app you won't need this function because your app will navigate to same route for each users regardless of ability
 * Please note role field is just for showing purpose it's not used by anything in frontend
 * We are checking role just for ease
 * NOTE: If you have different pages to navigate based on user ability then this function can be useful. However, you need to update it.
 * @param {String} userRole Role of user
 */
export const getHomeRouteForLoggedInUser = (userRole) => {
  if (userRole == "admin") return "/";
  if (userRole == "client") return { name: "access-control" };
  return { name: "auth-login" };
};
