export default [
  {
    path: "/register",
    name: "users-register",
    component: () => import("@/views/register/Register.vue"),
    meta: {
      layout: "full",
      resource: "Auth",
      redirectIfLoggedIn: true,
    },
  },
  {
    path: "/signup/verify-email",
    name: "verifyEmail",
    component: () => import("@/views/register/VerifyEmail.vue"),
    meta: {
      rule: "isPublic",
      requiredAuth: false,
    },
  },
];
