import { getAccessTokenFromCookie } from "@/auth/utils";
import axios from "@axios";

const state = {
  user: {},
};

const actions = {
  fetchUser({ commit }) {
    return axios.post("/user-info",{
    });
  },
  async updatePassword({ commit }, data) {
    return await axios.post("/update-password", {
      data,
    });
  },
  async updateInfo({ commit }, data) {
    const response = await axios.post("/update-info", {
      data,
    });
    const data_ = getAccessTokenFromCookie();
    data_[2] = response.data.user;
    const newData = {
      accessToken: data_[0],
      userAccess: data_[1],
      userData: data_[2],
    };
    const cookieValue = JSON.stringify(newData);
    var expires = new Date();
    expires.setMonth(expires.getMonth() + 1);
    expires = expires.toUTCString();
    document.cookie = `auth_data=${cookieValue}; expires=${expires}; path=/; Secure; SameSite=Strict;`;
    localStorage.setItem("userData", JSON.stringify(response.data.user));
    commit("setUser", response.data.user);
    return response;
  },
};


const getters = {
  getUser: (state) => state.user,
};

const mutations = {
  setUser: (state, user) => (state.user = user),
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
